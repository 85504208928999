import React from 'react'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Timeline from '../../../components/ui/timeline-ru'
import ImageOne from '../../../data/images/bg/machines/smt/aoi/aoi-inline.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "ЛИНИЯ AOI",
            image: ImageOne,
            title: "Отдельная линия для оптической инспекции",
            desc: "При наличии двух – трех небольших линий поверхностного монтажа может быть дорого устанавливать хорошую систему автоматической оптической инспекции для каждой линии. А простые машины AOI могут не обнаруживать дефекты критичные для изделий с повышенными требованиями к эксплуатации. В таких случая оптимальное решение – организовать отдельную линию для оптической инспекции. Линия имеет простую комплектацию и состоит из машины  AOI, устройств загрузки и выгрузки плат, отбраковочного конвейера или буферного устройства",
            path: "/комплексные-решения/линия-оптической-инспекции/отдельная-линия",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "ЛІНІЯ AOI",
            image: ImageOne,
            title: "Окрема лінія для оптичної інспекції",
            desc: "За наявності двох – трьох невеликих ліній поверхневого монтажу може бути дорого встановлювати хорошу систему автоматичної оптичної інспекції для кожної лінії. А прості машини AOI можуть не виявляти критичні дефекти для виробів з підвищеними вимогами до експлуатації. У таких випадках оптимальне рішення – організувати окрему лінію для оптичної інспекції. Лінія має просту комплектацію та складається з машини AOI, пристроїв завантаження та вивантаження плат, відбракувального конвеєра або буферного пристрою",
            path: "/ua/комплексні-рішення/лінія-оптичної-інспекції/окрема-лінія",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Автоматическая оптическая инспекция – передовое решение для технологических процессов управления качеством и показатель высокого уровня организации производства. Системы применяются для контроля нанесения паяльной пасты на печатные плати (SPI) и для контроля установки и монтажа электронных компонентов (AOI). Для многономенклатурного производства может быть проблемой комплектовать каждую небольшую линию дорогой машиной AOI. При наличии небольшого количества простых линий на базе одного установщика оптимальное решение – организация отдельной линии автоматической оптической инспекции.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Автоматична оптична інспекція - передове рішення для технологічних процесів управління якістю та показник високого рівня організації виробництва. Системи застосовуються для контролю нанесення паяльної пасти на друковані плати (SPI) та контролю встановлення та монтажу електронних компонентів (AOI). Для багатономенклатурного виробництва може бути проблемою комплектувати кожну невелику лінію дорогою машиною AOI. За наявності невеликої кількості простих ліній з одним установником оптимальне рішення – організація окремої лінії автоматичної оптичної інспекції.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
